import axios from 'axios';
import {createInstance} from '@/services/axios-instance';
import {LatLng} from '@/types/lat-lng';
import VehicleCategory from '@/types/vehicle-category';
import {format} from 'date-fns';
import {getJwt} from "@rental/prebooking-ui-utils";

const instance = createInstance({
  baseURL: '/api/rental/prebooking-service/external/prebooking',
});

interface SnPrebookingRequest {
  city: number;
  vehicleType: string;
  startDate: Date;
  endDate: Date;
  address: string;
  location: LatLng;
  packages: number[];
  insurance: number | null;
  totalAmount: number;
  language: string;
  decodedOfferToken: string;
  // 🧽 2. Set structure here:
  leadGenerator: string | null,
  leadPlatform: string | null,
  partnerBookingId: string | null,
}

interface SnAppPrebookingRequest extends SnPrebookingRequest {
  token: string;
  selectedOfferToken: string;
}

interface SnWebPrebookingRequest extends SnPrebookingRequest {
  email: string;
  firstName: string;
  lastName: string;
}

interface PrebookingResponse {
  preBookingUuid: string;
  shouldRegister: boolean;
  additionalDriverCode: string | null;
}

export const createSnPrebooking = async (
  prebookingRequest: SnAppPrebookingRequest,
): Promise<PrebookingResponse> => {
  const selectedExtraIds = prebookingRequest.insurance ? [prebookingRequest.insurance, ...prebookingRequest.packages] : prebookingRequest.packages;
  const payload = {
    jwtToken: prebookingRequest.token,
    address: prebookingRequest.address,
    latitude: prebookingRequest.location.lat,
    longitude: prebookingRequest.location.lng,
    locationId: prebookingRequest.city,
    vehicleCategory: prebookingRequest.vehicleType,
    rentalStartsAtMillis: format(prebookingRequest.startDate, 'T'),
    rentalEndsAtMillis: format(prebookingRequest.endDate, 'T'),
    language: prebookingRequest.language,
    totalAmount: prebookingRequest.totalAmount,
    decodedOfferToken: prebookingRequest.decodedOfferToken,
    selectedExtraIds: selectedExtraIds,
    selectedOfferToken: prebookingRequest.selectedOfferToken
  };
  const response = await instance.post('/', payload);
  return response.data;
};

export const createWebPrebooking = async (
  prebookingRequest: SnWebPrebookingRequest,
): Promise<PrebookingResponse> => {
  const selectedExtraIds = prebookingRequest.insurance ? [prebookingRequest.insurance, ...prebookingRequest.packages] : prebookingRequest.packages;
  const payload = {
    address: prebookingRequest.address,
    latitude: prebookingRequest.location.lat,
    longitude: prebookingRequest.location.lng,
    locationId: prebookingRequest.city,
    vehicleCategory: prebookingRequest.vehicleType,
    rentalStartsAtMillis: format(prebookingRequest.startDate, 'T'),
    rentalEndsAtMillis: format(prebookingRequest.endDate, 'T'),
    language: prebookingRequest.language,
    totalAmount: prebookingRequest.totalAmount,
    decodedOfferToken: prebookingRequest.decodedOfferToken,
    selectedExtraIds: selectedExtraIds,
    email: prebookingRequest.email,
    firstName: prebookingRequest.firstName,
    lastName: prebookingRequest.lastName,
    // 🧽 3.3. Set new fields hereЖ
    leadGenerator: prebookingRequest.leadGenerator,
    leadPlatform: prebookingRequest.leadPlatform,
    partnerBookingId: prebookingRequest.partnerBookingId
  };
  const response = await instance.post('/', payload);
  return response.data;
};

export const trackTNCAcceptance = async (roamingLegalEntity: number) => {
  const jwtToken = await getJwt()
  const payload = {roamingLegalEntity, jwtToken};
  const response = await instance.post('/tnc-tracking', payload);
  return response.data;
}

interface PartnerPrebookingRequest {
  vehicleType: VehicleCategory;
  startDate: Date;
  endDate: Date;
  packages: number[];
  insurance: number | null;
  language: string;
  token: string;
  decodedOfferToken: string;
  locationId: number;
}

export const createMbrPrebooking = async (
  prebookingRequest: PartnerPrebookingRequest,
): Promise<{ preBookingUuid: string; shouldRegister: boolean }> => {
  const selectedExtraIds = prebookingRequest.insurance ? [prebookingRequest.insurance, ...prebookingRequest.packages] : prebookingRequest.packages;
  const payload = {
    vehicleCategory: prebookingRequest.vehicleType,
    rentalStartsAtMillis: format(prebookingRequest.startDate, 'T'),
    rentalEndsAtMillis: format(prebookingRequest.endDate, 'T'),
    language: prebookingRequest.language,
    decodedOfferToken: prebookingRequest.decodedOfferToken,
    selectedExtraIds: selectedExtraIds,
    jwtToken: prebookingRequest.token,
    locationId: prebookingRequest.locationId,
  };
  const response = await instance.post('/partner', payload);
  return response.data;
};

export interface VehicleCategoryData {
  vehicleCategory: VehicleCategory;
  buildSeries: string[];
  excludeElectric: boolean;
  isBookable: boolean;
  minimumAge: number;
  meetsAgeRequirement: boolean;
  isElectric?: boolean;
  isAutomatic?: boolean;
  isHybrid?: boolean;
  seats?: number;
}

export interface LocationConfig {
  maxBookingDays: number;
  earliestPrebookingPossibleAt: string;
  latestPrebookingPossibleAt: string;
  defaultVehicleCategory: VehicleCategory;
  cancellationNoFeeTimeoutHours: number;
  driverNotValidatedTimeout: number;
  partner: PartnerData | null;
  isDevelopment: boolean;
}

export interface PartnerData {
  partnerStation: PartnerStationConfig;
  vehicleCategoryData: VehicleCategoryData[];
}

export interface PartnerStationConfig {
  weekDaysOpened: number[];
  closingTime: string;
  openingTime: string;
  timeZone: string;
  earliestPossiblePickup: string;
  latestPossiblePickup: string;
  maxBookingDays: number;
  publicHolidays: string[];
  address: string;
  email: string;
}

export const getLocationConfig = async (locationId: number) => {
  const response = await instance.get<LocationConfig>(`/config/${locationId}`);
  return response.data;
};

interface VehicleCategoriesRequest {
  startDate: string,
  jwt: string | null,
}

export const getVehicleCategories = async (locationId: number, request: VehicleCategoriesRequest) => {
  const response = await instance.post<VehicleCategoryData[]>(`/config/${locationId}/vehicles`, request);
  return response.data;
}

export const getDevelopmentData = async (): Promise<{ locationId: number, isDevelopment: boolean }[]> => {
  const response = await instance.get('/config/development');
  return response.data;
};

export const createCancelToken = () => {
  return axios.CancelToken.source();
};


export const LEAD_PLATFORMS =  ['KAYAK', 'SKYSCANNER', 'LILIGO'] as const
export type LeadPlatform = typeof LEAD_PLATFORMS[number]

export const LEAD_GENERATORS =  ['META' , 'LUFTHANSA'] as const
export type LeadGenerator = typeof LEAD_GENERATORS[number]

interface MetaRedirectTrackingEvent {
  leadGenerator?: LeadGenerator
  leadPlatform?: LeadPlatform
  partnerBookingId?: string
  latitude?: number
  longitude?: number
  startDate?: string
  endDate?: string
  address?: string
  locationId?: number
  category?: string
}

export const createMetaRedirectEvent = async (event: MetaRedirectTrackingEvent): Promise<void> => {
  const response = await instance.post('/meta/redirect', event);
  return response.data;
}
